<template>
  <AppLayout>
    <div class="sub-category">
      <div class="container">
        <PageHeader
          title="返回"
          back
          hidden-breadcrumb
          style="margin-bottom: 15px;margin-top: 15px;padding: 0;"
          @click="onBack()"
        />
        <!-- 公司名 logo -->
        <Row style="margin-bottom: 20px">
          <Col flex="65px">
            <div
              style="
                height: 64px;
                width: 64px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #cfcdcd;
              "
            >
              <img :src="require('/static/icons/logo.png')" />
            </div>
          </Col>
          <Col flex="auto">
            <div
              style="
                height: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <Row>
                <span
                  style="
                    color: #333333;
                    font-size: 18px;
                    font-weight: 550;
                    margin-left: 10px;
                  "
                >
                  {{ merchantStore.businessName }}
                </span>
              </Row>
            </div>
            <div
              style="
                height: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <Row>
                <span
                  style="
                    color: #333333;
                    font-size: 14px;

                    margin-left: 10px;
                  "
                >
                  会面码：
                  <span style="color: #d12b23">
                    {{ merchantStore.meetCode }}
                  </span>
                </span>
              </Row>
            </div>
          </Col>
        </Row>
        <!-- 公司信息 -->
        <div v-for="item in companyData" :key="item.contactPhone" class="item">
          <Row style="border: 1px solid #eee; height: 42px">
            <Col span="4">
              <span style="margin-left: 20px">{{ item.key }}</span>
            </Col>
            <Col span="18">
              <span style="margin-left: 20px">{{ item.value }}</span>
            </Col>
          </Row>
        </div>
        <!-- 商品区块-->
        <div class="goods-list">
          <!-- 商品列表 -->
          <GoodsMerchantList v-if="goods" :goods="goods" />
          <!-- 无限列表加载组件 -->
          <XtxInfiniteLoading

          />
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout'
import GoodsMerchantList from '@/views/category/components/GoodsMerchantList'

export default {
  name: 'SubCategoryPage',
  components: { GoodsMerchantList, AppLayout },
  data () {
    return {
      goods: {},
      merchantStore: {},
      productList: {},
      companyData: [],
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.$store
        // .dispatch('StoreDetail', '479aa154734f451dadaabbfbc7d2d3f3')
        .dispatch('StoreDetail', this.$route.params.id)
        .then((response) => {

          this.merchantStore = response.result.merchantStore
          this.companyData.push({
            key: '电话',
            value: this.merchantStore.contactPhone,
          })
          this.companyData.push({
            key: '邮箱',
            value: this.merchantStore.email,
          })
          this.companyData.push({
            key: '地址',
            value: this.merchantStore.address,
          })
          this.goods = response.result.productList

        })
        .catch(() => {})
    },
    onBack () {
      this.$router.push('/')
    },
  },
  setup () {
    return {}
  },
}
</script>

<style scoped lang="less">
.goods-list {
  background: #fff;
  padding: 0 25px;
  margin-top: 25px;
}
/deep/ .ivu-col-span-4 {
  background: #f8f8fb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .ivu-col-span-18 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
}
</style>
